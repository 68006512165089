import React from "react"
import Layout from "../components/Layout" 
import { graphql } from "gatsby"

const WorkPage = ({ data }) => {

  return (
    <>
      <Layout>          
            <div className="list">
              <div className="col">
                <div className="project">
                  <div className="img"></div>
                  <div className="label">
                    <h3>LE ROI EST MORT</h3>
                    <p>2022</p>
                  </div>
                </div>
                <div className="project">
                  <div className="img"></div>
                  <div className="label">
                    <h3>LE ROI EST MORT</h3>
                    <p>2022</p>
                  </div>
                </div>
                <div className="project">
                  <div className="img"></div>
                  <div className="label">
                    <h3>LE ROI EST MORT</h3>
                    <p>2022</p>
                  </div>
                </div>
                <div className="project">
                  <div className="img"></div>
                  <div className="label">
                    <h3>LE ROI EST MORT</h3>
                    <p>2022</p>
                  </div>
                </div>
                <div className="project">
                  <div className="img"></div>
                  <div className="label">
                    <h3>LE ROI EST MORT</h3>
                    <p>2022</p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="project">
                  <div className="img"></div>
                  <div className="label">
                    <h3>LE ROI EST MORT</h3>
                    <p>2022</p>
                  </div>
                </div>
                <div className="project">
                  <div className="img"></div>
                  <div className="label">
                    <h3>LE ROI EST MORT</h3>
                    <p>2022</p>
                  </div>
                </div>
                <div className="project">
                  <div className="img"></div>
                  <div className="label">
                    <h3>LE ROI EST MORT</h3>
                    <p>2022</p>
                  </div>
                </div>
                <div className="project">
                  <div className="img"></div>
                  <div className="label">
                    <h3>LE ROI EST MORT</h3>
                    <p>2022</p>
                  </div>
                </div>
                <div className="project">
                  <div className="img"></div>
                  <div className="label">
                    <h3>LE ROI EST MORT</h3>
                    <p>2022</p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="project">
                  <div className="img"></div>
                  <div className="label">
                    <h3>LE ROI EST MORT</h3>
                    <p>2022</p>
                  </div>
                </div>
                <div className="project">
                  <div className="img"></div>
                  <div className="label">
                    <h3>LE ROI EST MORT</h3>
                    <p>2022</p>
                  </div>
                </div>
                <div className="project">
                  <div className="img"></div>
                  <div className="label">
                    <h3>LE ROI EST MORT</h3>
                    <p>2022</p>
                  </div>
                </div>
                <div className="project">
                  <div className="img"></div>
                  <div className="label">
                    <h3>LE ROI EST MORT</h3>
                    <p>2022</p>
                  </div>
                </div>
                <div className="project">
                  <div className="img"></div>
                  <div className="label">
                    <h3>LE ROI EST MORT</h3>
                    <p>2022</p>
                  </div>
                </div>
              </div>

            </div>
      </Layout>
    </>
  )
}

export default WorkPage

//export const query = graphql`
//  {
//    BlogPostQuery: allMarkdownRemark(
//      sort: { fields: [frontmatter___date], order: DESC }
//      limit: 3
//    ) {
//      totalCount
//      edges {
//        node {
//          frontmatter {
//            title
//            date
//            path
//            featuredImage {
//              childImageSharp {
//                gatsbyImageData(
//                  layout: FULL_WIDTH
//                  placeholder: TRACED_SVG
//                  formats: [AUTO, WEBP]
//                )
//              }
//            }
//            featuredImageAlt
//          }
//          excerpt
//        }
//      }
//    }
//  }
//`
